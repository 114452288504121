@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.app-container {
  text-align: center;
  font-family: 'Quicksand', sans-serif;
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
