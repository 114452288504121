.profile-container {
  display: flex;
  justify-content: center;
  font-size: 4vw;
  font-family: 'Questrial', sans-serif;
  margin-bottom: 0;
}

.profile-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 60px;
  flex: 0 0 auto;
  align-self: center;
}

.profile-image {
  width: 50vw;
  max-width: 280px;
  height: auto;
  object-fit: cover;
}

.enclosure {
  color: #2c2a2a;
  align-self: center;
  font-size: 160px;
}

@media screen and (max-width: 560px) {
  .enclosure {
    margin-right: -20px;
    margin-left: -20px;
    font-size: 20vw;
  }
}