@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

.home {
    min-height: 100vh;
    max-width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../assets/background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: rgb(40, 40, 40);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.title {
    color: rgba(242, 242, 242, 0.9);
    font-weight: bold;
    font-size: 5vw;
    font-family: 'Nunito', sans-serif;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 60px;
}

@media screen and (max-width: 800px) {
    .title {
        font-size: 40px;
    }
}

.construction {
    color: rgba(242, 242, 242, 0.9);
    font-size: 2vw;
    font-family: 'Nunito', sans-serif;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 100%;
    margin-top: -60px;
    margin-bottom: 60px;
}

@media screen and (max-width: 800px) {
    .construction {
        font-size: 20px;
    }
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.button-container .MuiButton-root {
    color: white;
    border: 1px solid #fff;
    background: transparent;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.4s ease 0s;
    padding: 10px 30px;
    background: rgba(555, 555, 555, 0.05);
}

.button-container .MuiButton-root:hover {
    background-color: #555;
    color: #fff;
    box-shadow: 0px 0px 5px #555;
}

.cursor {
    color: #ffff;
}

.invisible-cursor {
    color: transparent;
}

.wordcloud-section {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/test.png");
    padding-top: 90px;
    padding-bottom: 160px;
    background-color: #cfc9c9;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .wordcloud-section {
        padding-bottom: 160px;
    }
}

.mywork-section {
    min-height: 100vh;
    background-color: rgba(197, 201, 202, 0.4);
    align-items: center;
}

.sections-wrapper {
    position: relative;
}
